import React, {useEffect, useState}  from "react";
import { Link } from 'gatsby';
import { Button, Hero, ExternalIcon } from "components/ui";
import { WhatsNew, MoreResources, CurrentTopics, LatestWebinars, AboutNCSACW } from "components/containers/Homepage/index";
import HomepageHeroDesktop from "assets/images/hero-home-1200x426-trauma-informed-care-toolkit-nov2024.jpg";
import HomepageHeroTablet from "assets/images/hero-home-tablet-1040x425-trauma-informed-care-toolkit-nov2024.jpg";
import HomepageHeroDesktopTemp from "assets/images/hero-home-1200x426-family-centered-approach-nov2024.jpg";
import HomepageHeroTabletTemp from "assets/images/hero-home-tablet-1040x425-family-centered-approach-nov2024.jpg";
import { DateTime } from "luxon";

// const heroContent={
//   title: "Safety & Risk Practice Guides",
//   link: "topics/safety-and-risk/safety-risk-guides-series"
// }
const heroContent={
  title: "Trauma-Informed Care Toolkit",
  description: "Will help teams 1) understand the interplay between trauma and substance use and 2) enhance policies and practices to be more trauma-informed and responsive to the needs of those they serve.",
  link: "topics/trauma-informed-care/trauma-toolkit-series"
}
const tempHeroContent={
  title: "FTC: A Family-Centered Approach",
  description: "Join this webinar to learn how family treatment courts (FTCs) use a family-centered approach to achieve better outcomes for families affected by substance use disorders.",
  link: "https://cffutures.zoom.us/webinar/register/WN_UZCgHK3pQUeUucdy1Incqw"
}

const Home = () => {
  const [currentDate, setCurrentDate] = useState(false);
  const timeZone = "America/New_York"
  // December 17, 2024 at 2:00pm
  const year = 2024;
  const month = 12;
  const day = 17;
  const hour = 14;
  const minute = 0;
  const updateHeroDate = DateTime.fromObject({year:year,month:month,day:day,hour:hour,minute:minute},{zone:timeZone});

  useEffect(()=>{
    setCurrentDate(DateTime.now().setZone(timeZone));
  }, [])
  return(
    <>
        <Hero
        desktopImg={currentDate > updateHeroDate ? HomepageHeroDesktop:HomepageHeroDesktopTemp}
        tabletImg={currentDate > updateHeroDate ? HomepageHeroTablet:HomepageHeroTabletTemp}
        color={"#363B50"}
        textColor="#fff"
        headingColor="#fff"
        isHomeHero={true}
        >
          <h1 name="main-content" id="main-content" className="usa-hero__heading" aria-label={`Hero: ${currentDate > updateHeroDate ? heroContent.title : tempHeroContent.title}`}>{currentDate > updateHeroDate ? heroContent.title : tempHeroContent.title}</h1>
          {currentDate > updateHeroDate ? <p>{heroContent.description}</p>:<p>{tempHeroContent.description}</p>}
          {currentDate > updateHeroDate ?
            <Button
            color="usa-button--secondary"
            labelText={`Open ${heroContent.title} page`}
            link={heroContent.link}
            >
              View Series
            </Button>
            :
            <div className="display-flex">
              <a 
              href={tempHeroContent.link}
              className="usa-button usa-button--secondary"
              aria-label={`Register for ${tempHeroContent.title} webinar`}
              target="_blank" 
              rel="noreferrer"
              >
                Register Now
              </a>
              <ExternalIcon iconColor="white" />
            </div>
          }
        </Hero>
        <div className="light-gray-bg">
          <div className="grid-container">
            <div className="grid-row">
              <WhatsNew />
              <LatestWebinars />
            </div>
          </div>
        </div>
        <AboutNCSACW />
        <div className="blue-bg padding-y-5">
          <CurrentTopics />
        </div>
      <MoreResources />
    </>
  )
}

export default Home;
