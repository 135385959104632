import React from "react";
import {Button} from "components/ui";
import styled from "styled-components";
import { Link } from "gatsby"

const whatsNewData = [
    {
        id: 1,
        title: "Training and Technical Assistance Resource Catalog",
        link: "/topics/professional-development/training-technical-assistance-resource-catalog"
    },
    {
        id: 2,
        title: "Collaborative Teams Toolkit for Trauma-Informed Care",
        link: "/topics/trauma-informed-care/trauma-toolkit-series"
    },
    {
        id: 3,
        title: "ICWA Active Efforts Support Toolkit",
        link: "/topics/american-indian-alaska-native/ICWA-active-efforts-series"
    },
    {
        id: 4,
        title: "Cannabis Tip Sheets Series",
        link: "/topics/childhood-and-adolescence/cannabis-series"
    }
];
const WhatsNew = () => {
    const WhatsNewWrapper = styled.div`
    .view-more{
        @media(min-width:1024px){
            margin-top: 171px;
        }
        @media(min-width:963px) and (max-width:1024px){
            margin-top: 175px;
        }
        @media (min-width:880px) and (max-width:963px){
            margin-top: 148px;
        }
        @media (max-width:880px){
            margin-top: 70px;
            text-align: center;
        }
    }
`;
    return (
        <WhatsNewWrapper className="tablet-lg:grid-col-6 tablet-lg:padding-right-7 tablet:grid-col-12 margin-y-5">
            <h2>What's New on NCSACW</h2> 
            <div>
                {whatsNewData.map((item, idx)=>{
                    return <div
                    key={`whats-new-item-${item.id}`}
                    className={`padding-y-1 ${idx === 0 ? "":"border-top border-base-lighter"}`}
                    >
                        <p>
                            {item.fileSize ? <a href={item.link} target="_blank" rel="noreferrer" aria-label={`Open ${item.title} in a new window`}>{item.title.substring(0,146)}</a> : <Link to={item.link}>{item.title.substring(0,146)}</Link>}
                            {item.title.length > 146 && <span>...</span>}
                            {item.fileSize && <span> (PDF {item.fileSize})</span>}
                        </p> 
                    </div>
                })}
                <div className="view-more">
                    <Button
                    link="/whats-new"
                    color="primary-button"
                    size="padding-x-5"
                    labelText="View more resources"
                    >
                        View More
                    </Button>
                </div>
            </div>
        </WhatsNewWrapper>
    )
}

export default WhatsNew;
